import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectComponent = ({ to, message = "Redirecting..." }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = to;
    }, 2000); // Optional delay to enhance the user experience

    return () => clearTimeout(timeout); // Cleanup timeout on component unmount
  }, [to]);

  return (
    <div className="flex items-center justify-center h-screen bg-base-200 bg-black">
      <div className="text-center">
        <div className="flex justify-center">
          <span className="loading loading-spinner text-primary"></span>
        </div>
        <p className="mt-4 text-lg text-white font-semibold">{message}</p>
        <p className="text-sm text-gray-100">
          If you are not redirected automatically, <a href={to} className="link text-primary font-semibold">click here</a>.
        </p>
      </div>
    </div>
  );
};

export default RedirectComponent;
