import React from "react";
import { motion } from "framer-motion";
import { Button } from "@headlessui/react";
import { FaGithub, FaWpforms, FaDiscord, FaYoutube, FaHtml5, FaCss3Alt, FaReact, FaNodeJs, FaVuejs } from "react-icons/fa";
import { SiJavascript, SiTailwindcss, SiTypescript, SiMongodb } from "react-icons/si";
import { IoGitPullRequest } from "react-icons/io5";
import { IoStatsChart } from "react-icons/io5";

const Hero = () => (
  <section className="bg-gradient-to-b from-slate-900 to-black text-white py-20 relative overflow-y-auto">
    {/* Animated Background */}
    <div className="absolute inset-0 -z-10">
      <motion.div
        className="w-full h-full bg-gradient-to-r from-purple-500 to-pink-500 animate-gradient"
        style={{
          backgroundSize: "400% 400%",
          animation: "gradientMove 10s ease infinite",
        }}
      ></motion.div>
    </div>
    <motion.div
      className="container mx-auto px-4 relative z-10"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <motion.h1
        className="text-6xl font-extrabold mb-6 text-center"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        Welcome!
      </motion.h1>
      <motion.p
        className="text-xl mb-8 text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Heya! Welcome to my portfolio.
      </motion.p>
      <motion.div
        className="flex justify-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <Button
          as="a"
          href="https://request.notreal003.xyz"
          className="inline-flex items-center gap-2 px-8 py-3 bg-indigo-600 hover:bg-indigo-500 text-white rounded-full font-semibold text-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          <IoGitPullRequest className="text-lg" /> Requests Portal
        </Button>
      </motion.div>
    </motion.div>
  </section>
);

const BioSection = () => (
  <section className="bg-gray-900 text-white py-20 overflow-hidden">
    <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center gap-12">
      <motion.div
        className="md:w-1/2"
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <img
          src="https://i.postimg.cc/ncrd09pQ/IMG-7752.jpg" // Replace with your image path
          alt="NotReal003"
          className="rounded-full w-48 h-48 object-cover mx-auto mb-6 shadow-xl border-4 border-indigo-500"
        />
      </motion.div>
      <motion.div
        className="md:w-1/2 text-center md:text-left"
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h2 className="text-4xl font-bold mb-6">About Me</h2>
        <p className="text-lg leading-relaxed">
          I'm NotNT, now known as NotReal003, a passionate gamer, content creator, and <strong className="text-indigo-400">Developer</strong>. I'm diving
          deep into web development, exploring the realms of{" "}
          <strong className="text-indigo-400">React</strong>, <strong className="text-indigo-400">JavaScript</strong>,{" "}
          <strong className="text-indigo-400">Html</strong>, and <strong className="text-indigo-400">Css</strong>.
          My journey is all about continuous learning and turning ideas into reality.
        </p>
        <p className="mt-6 text-lg leading-relaxed">
          If you have any inquiries or collaboration opportunities, feel free to{" "}
          <a
            href="discord:/users/1131271104590270606"
            className="text-blue-400 hover:underline transition duration-300 ease-in-out"
          >
            contact me
          </a>
          . Let's connect and build something amazing together!
        </p>
      </motion.div>
    </div>
  </section>
);

const CTASection = () => (
  <section className="bg-gradient-to-b from-slate-900 to-black text-white py-16">
    <div className="container mx-auto px-4 flex flex-wrap justify-center gap-6">
      {[
        { href: "https://github.com/NotReal003", icon: <FaGithub />, label: "GitHub", external: false },
        { href: "https://request.notreal003.xyz/apply", icon: <FaWpforms />, label: "SkyLine Guild", external: false },
        { href: "/player", icon: <IoStatsChart />, label: "Players", external: true },
        {
          href: "https://youtube.com/@NotNT77?si=j7kgkkCkrha0aNNc",
          icon: <FaYoutube />,
          label: "YouTube",
        },
        { href: "https://discord.gg/sqVBrMVQmp", icon: <FaDiscord />, label: "Discord" },
      ].map(({ href, icon, label }, index) => (
        <motion.a
          key={index}
          href={href}
          className="inline-flex items-center gap-2 px-8 py-3 bg-indigo-600 hover:bg-indigo-500 text-white rounded-full font-semibold text-lg transition duration-300 ease-in-out transform hover:scale-105"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {icon} {label}
        </motion.a>
      ))}
    </div>
  </section>
);

const Features = () => (
  <section className="bg-gray-900 text-white py-20">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl font-bold mb-12 text-center">Projects</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
        {[
          {
            title: "Requests Portal",
            description: `This project is a comprehensive request management system that allows users to submit various types of requests, including reports, support requests, and applications. Administrators have the ability to manage these requests through an administrative dashboard. \n The system utilizes React for the frontend and Node.js with MongoDB for the backend. Key features include JWT-based authentication, email notifications, request status updates, and an admin dashboard for managing requests.`,
            icon: <FaNodeJs />,
            url: "https://request.notreal003.xyz",
          },
          {
            title: "Support",
            description: "Find detailed articles and FAQs on our dedicated HelpDesk.",
            url: "https://support.notreal003.xyz",
            icon: <FaVuejs />,
          },
          {
            title: "Data Privacy",
            description: "No data is collected from users on this website. Your privacy is important to us, and we do not store or process any personal information. Feel free to explore our content without concerns about your data being collected. Read more about GitHub privacy policy by clicking here! and Read about our",
            icon: <SiMongodb />,
            url: "https://support.notreal003.xyz/privacy",
          },
        ].map(({ title, description, icon, url }, index) => (
          <motion.a
            key={index}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gray-800 p-8 rounded-xl shadow-lg transform transition duration-500 ease-in-out hover:scale-105 hover:shadow-2xl"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="text-4xl mb-6 text-center text-indigo-400">{icon}</div>
            <h3 className="text-2xl font-bold mb-4">{title}</h3>
            <p className="text-gray-300">{description}</p>
          </motion.a>
        ))}
      </div>
    </div>
  </section>
);

// ... (Previous sections: Hero, BioSection, CTASection, Features)

const Skills = () => (
  <section className="bg-gradient-to-b from-slate-900 to-black text-white py-20 overflow-hidden">
    <div className="container mx-auto px-4 text-center">
      <motion.h2
        className="text-4xl font-bold mb-12"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Skills
      </motion.h2>
      <div className="flex flex-wrap justify-center gap-8">
        {[
          { name: "React", icon: <FaReact className="text-indigo-500" />, color: "indigo" },
          { name: "JavaScript", icon: <SiJavascript className="text-yellow-400" />, color: "yellow" },
          { name: "TypeScript", icon: <SiTypescript className="text-indigo-600" />, color: "indigo" },
          { name: "MongoDB", icon: <SiMongodb className="text-green-400" />, color: "green" },
          { name: "TailwindCss", icon: <SiTailwindcss className="text-indigo-700" />, color: "indigo" },
          { name: "HTML", icon: <FaHtml5 className="text-orange-500" />, color: "orange" },
          { name: "CSS", icon: <FaCss3Alt className="text-indigo-400" />, color: "indigo" },
        ].map(({ name, icon, color }, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center gap-4 p-6 bg-gray-800 rounded-xl shadow-lg transform transition duration-500 ease-in-out hover:scale-110 hover:shadow-2xl"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div
              className={`text-5xl p-4 rounded-full bg-gradient-to-r from-${color}-500 to-${color}-700 shadow-lg`}
            >
              {icon}
            </div>
            <p className="mt-4 text-xl font-semibold">{name}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </section>
);

const Home = () => (
  <>
    <Hero />
    <BioSection />
    <CTASection />
    <Features />
    <Skills />
  </>
);

export default Home;
